import React from 'react';
import { useCartContext } from '../Context/CartContext';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "../ItemCart/Itemcart.css";
import toast, { Toaster } from 'react-hot-toast';
import { Button } from 'react-bootstrap';

const ItemCart = ({ item }) => {
  const { removeProduct, selectedSize, acomp, selectedDrink, selectedDip, anotaciones } = useCartContext();

  // Función para obtener el precio del producto según su tamaño
  const getPrice = () => {
    if (selectedSize === "simple") {
      return item.simple;
    } else if (selectedSize === "doble") {
      return item.doble;
    } else {
      return 0; // Puedes manejar el caso por defecto aquí
    }
  };

  // Función para obtener el acompañamiento seleccionado
  const getAcompañamiento = () => {
    if (acomp === "Papas fritas") {
      return item.acompañamiento;
    } else if (acomp === "Aros de cebolla") {
      return item.acompañamiento1;
    }
  };

  // Calcular el costo adicional por medallones extra
  const getCostoMedallonesExtra = () => {
    return item.precioExtras || 0; // Asegúrate de tener item.precioExtras en tu objeto de producto del carrito
  };

  const price = getPrice();
  const acompañamiento = getAcompañamiento();
  const costoMedallonesExtra = getCostoMedallonesExtra(); // Obtener el costo adicional por medallones extra

  const subtotal = item.quantity * (price + costoMedallonesExtra); // Calcular el subtotal incluyendo medallones extra

  const notify1 = () => {
    toast.error('Combo eliminado!');
    removeProduct(item.id, selectedSize, acomp, selectedDrink, selectedDip);
  };

  return (
    <Container fluid className='itemCart' style={{ border: "1px solid black" }}>
      <Row>
        <Col>
          <img src={item.image} alt={item.title} className='item-modal' />
        </Col>
        <Col>
        <div>
            <p><b>Nombre:</b> {item.name}</p>
            <p><b>Tamaño:</b> {item.selectedSize}</p>
            <p><b>Cantidad:</b> {item.quantity}</p>
            <p><b>Acompañamiento:</b> {item.acomp}</p>
            <p><b>Salsa:</b> {item.selectedDip}</p>
            <p><b>Bebida:</b> {item.selectedDrink}</p>
            <p><b>Anotaciones:</b> {item.anotaciones}</p>
            <p><b>Medallones Extra:</b> {item.extras && item.extras.medallones}</p>
            <p><b>Precio por Combo:</b> ${item.price}</p>
            <p><b>Subtotal:</b> ${item.quantity * item.price}</p>
            <Button variant="danger" onClick={() => removeProduct(item.id, item.selectedSize, item.acomp, item.selectedDrink, item.selectedDip)}>Eliminar</Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ItemCart;
