import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import zona1 from "../../Imagenes/Zona 1.jpg";
import zona2 from "../../Imagenes/Zona 2.jpg";
import "../Delivery/zonas.css";
const Zonas = ({zona}) => {
  const [show, setShow] = useState(false);

const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  return (
<>
<div className="estilo-cart" onClick={handleShow}>
<a> {zona} </a>
</div>
<Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Zonas de delivery:</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                <div> 
                  <img className='zona' src={zona1} />
                  <img className='zona' src={zona2} />
                  </div>                       
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Volver
                    </Button>
                </Modal.Footer>
            </Modal>


    </>
  )
}

export default Zonas