import React from 'react';
import "../Footer/Footer.css";
import {Link} from 'react-router-dom'

const Footer = () => {
  return (
<footer className='backfooter' id='fot' >
    <p id='pie'>© 2024 sitio web creado por <a className='website' href='https://www.ninnotech.com' target='_blank' >www.ninnotech.com</a> Todos los derechos reservados.</p>
  </footer>
  )
}

export default Footer