import React from 'react'
import Logo from '../Logo/Logo'
import CartWidget from '../CartWidget/CartWidget'
import {Link} from 'react-router-dom'
import { useSelectedSizeContext } from '../../components/Context/SelectedContextSize';
import Zonas from '../Delivery/Zonas';
import "../Welcome/Welcome.css";


const NavBar = () => {
  const { selectedSize } = useSelectedSizeContext();
/*   console.log(selectedSize) */
  return (
    <div>
        <nav class="navbar navbar-expand-lg bg-body-tertiary" bg="dark" data-bs-theme="dark" >
  <div class="container-fluid">
    <Link class="navbar-brand" to="/"><Logo/></Link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <Link class="nav-link active" aria-current="page" to="/home">Home</Link>
        </li>
        <li class="nav-item">
          <Link class="nav-link" to={"/category/combos"}>Combos</Link>
        </li> 
        <li class="nav-item">
          <Link class="nav-link responsive"><Zonas zona={"Zona de delivery"}/></Link>
        </li> 
        <li class="nav-item">
        <Link class="nav-link" to='https://www.instagram.com/burbar.ok/?hl=es' target='_blank' ><i class="bi bi-instagram"></i></Link>
        </li> 
        <li class="nav-item">
        <Link class="nav-link" to={"/login"} ><i class="bi bi-gear"></i></Link>
        </li> 
{/*         <li class="nav-item">
          <Link class="nav-link" to={"/category/almohadas"}>Almohadas</Link>
        </li> */}
        {/* <li class="nav-item">
          <Link class="nav-link estilo"><CartWidget selectedSize={selectedSize}  /></Link>
        </li>
         */}
      </ul>
      <b style={{color:"White"}}>Pedir Nunca Fue Tan Facil App: Burbar</b>
    </div>
  </div>
</nav>
    </div>
  )
}

export default NavBar