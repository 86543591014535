import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useCartContext } from '../Context/CartContext';
import axios from 'axios';
const Dips = () => {
    const [dips, setItem] = useState([])
    const {id} = useParams()
    const {selectedDip, setSelectedDip} = useCartContext();


    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://pedir-pedir.p89nps.easypanel.host/sauces`);
          setItem(response.data);
        } catch (error) {
          console.error('Error al obtener el producto:', error);
        }
      };
    
      fetchData();
    }, [id]);


  const handleDipsChange = event => {
    setSelectedDip(event.target.value);
  };

  return (
    <div>
        <label className='underline' value=""><b>Seleccione una salsa</b></label>
        <br></br>
      <select value={selectedDip} onChange={handleDipsChange}>
        <option value="">Seleccione una salsa</option>
        {dips.map(dips => (
          <option key={dips.id} value={dips.name}>
            {dips.name}
          </option>
        ))}
      </select>
      <br></br>
      {selectedDip && <p>Seleccionaste: <b>{selectedDip}</b></p>}
    </div>
  );
};
  

export default Dips