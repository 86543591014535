import React from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';

const ExportOrdersButton = () => {
    const handleExportOrders = async () => {
        try {
            const response = await axios.get('https://pedir-pedir.p89nps.easypanel.host/orders', { responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `orders_finalizadas_${new Date().toISOString().slice(0, 10)}.xlsx`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error al exportar las órdenes:', error);
        }
    };

    return (
        <Button onClick={handleExportOrders}>Exportar Órdenes Finalizadas a Excel</Button>
    );
};

export default ExportOrdersButton;
