import React, { createContext, useState, useContext } from 'react';

const SelectedSizeContext = createContext();

export const useSelectedSizeContext = () => useContext(SelectedSizeContext);

export const SelectedSizeProvider = ({ children }) => {
  const [selectedSize, setSelectedSize] = useState();
  // Función para actualizar selectedSize
  const updateSelectedSize = (size) => {
    setSelectedSize(size);
  };
  return (
    <SelectedSizeContext.Provider value={{ selectedSize, setSelectedSize }}>
      {children}
    </SelectedSizeContext.Provider>
  );
};
