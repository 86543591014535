import React, { useState, useEffect } from 'react';
import "../Checkout/checkout.css";
import OrderDetails from './OrderDetails';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import ExportOrdersButton from './ExportOrdersButton';
const Order = () => {
  
  const [orders, setOrders] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('pendiente');
    const [selectedOrder, setSelectedOrder] = useState([]);
const [combosArray, setCombosArray] = useState([])
const [newStatus, setNewStatus] = useState(selectedOrder.estado || '')
const [key, setKey] = useState(0); // Estado para la clave
const [selectedBranchFilter, setSelectedBranchFilter] = useState('Selecciona una sucursal');

  
  
  //obtener ordenes para lado del cliente
  
  const handleStatusUpdate = async () => {
    try {
      await axios.put(`https://pedir-pedir.p89nps.easypanel.host/orders/${selectedOrder.uid}/status`, { newStatus });
      console.log('Estado de la orden actualizado correctamente en MySQL');
      setSelectedOrder({ ...selectedOrder, estado: newStatus }); // Actualiza el estado local de la orden en el cliente
    } catch (error) {
      console.error('Error al actualizar el estado de la orden en MySQL:', error);
    }
  };
  



  const handleCancelOrder = async () => {
    try {
    
       await axios.delete(`https://pedir-pedir.p89nps.easypanel.host/orders/${selectedOrder.uid}`);
/* 
        // Actualizar la lista de órdenes después de la cancelación
        fetchOrders(); */
    } catch (error) {
        console.error('Error al cancelar la orden:', error);
    }
};


  useEffect(() => {


    
    const fetchOrders = async () => {
      try {
        const response = await axios.get('https://pedir-pedir.p89nps.easypanel.host/orders/get');
        console.log("Ordenes obtenidas correctamente")
         setOrders(response.data); 
         console.log("orden numero:"+orders.map(order => order.uid) )
      } catch (error) {
        console.error('Error al recuperar las órdenes:', error);
      }
    };
  
    fetchOrders();
  
    // Actualizar las órdenes cada 5 segundos (por ejemplo)
    const intervalId = setInterval(() => {
      fetchOrders();
    }, 5000); // 5 segundos
  
    // Limpieza del intervalo cuando el componente se desmonta
    return () => clearInterval(intervalId);
  }, []);
  
  
  const handleBranchFilterChange = (event) => {
    setSelectedBranchFilter(event.target.value);
    setSelectedOrder(""); // Limpiar la orden seleccionada al cambiar la sucursal
    setCombosArray([]); // Limpiar el array de combos
    setNewStatus(''); // Limpiar el nuevo estado
    setKey(prevKey => prevKey + 1); // Cambiar la clave para reiniciar OrderDetails
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    return currentDate.toLocaleDateString('en-CA', { timeZone: 'America/Argentina/Buenos_Aires' }); // Formato 'yyyy-mm-dd'

};

const filteredOrders = orders.filter(order => order.selectedBranch === selectedBranchFilter)





    const handleStatusChange = (event) => {
      setSelectedStatus(event.target.value);
      setSelectedOrder(""); // Limpiar la orden seleccionada
      setCombosArray([]); // Limpiar el array de combos
      setNewStatus(''); // Limpiar el nuevo estado
      setKey(prevKey => prevKey + 1);  
    };
  
    const countOrders = status => orders.filter(order => order.estado === status && order.selectedBranch === selectedBranchFilter).length;
  


    
  
    const handleOrderSelect =  (event) => {
      console.log(orders)
        const orderId = event.target.value;
        console.log("NUMERO DE ORDEN DE PEDIDO:" + orderId)
        console.log("NUMERO DE UID:"+orders.map(order => order.uid))
        const selectedOrder = orders.find(order => parseInt(order.uid) === parseInt(orderId));
        setSelectedOrder(selectedOrder);
        console.log(selectedOrder)
        console.log(selectedOrder.combos)
        if(orders.find(order => parseInt(order.uid) === parseInt(orderId))){ 
          const parsedCombosArray = JSON.parse(selectedOrder.combos);
          setCombosArray(parsedCombosArray);
       console.log(combosArray)
       // Cambiar la clave del componente OrderDetails para reiniciarlo
    setKey(prevKey => prevKey + 1);
      }
    };


    return (
      <div fluid className='back'>
        <h2>Filtrar por estado de la orden:</h2>
        <h2>Fecha: {getCurrentDate()} </h2>
        <label>Seleccionar sucursal:</label>
      <select value={selectedBranchFilter} onChange={handleBranchFilterChange}>
        <option value="">Seleccionar sucursal...</option>
        <option value="Castelli 3067">Castelli</option>
        <option value="Patagones 305">Patagones</option>
        {/* Agregar más opciones según las sucursales disponibles */}
      </select>
        
        <div>
          <input
           className='l-config'
            type="radio"
            id="pendiente"
            name="orderStatus"
            value="pendiente"
            checked={selectedStatus === 'pendiente'}
            onChange={handleStatusChange}
          />
          <label htmlFor="pendiente">Pendiente ({countOrders('pendiente')})</label>
        
          <input
            className='l-config'
            type="radio"
            id="en preparacion"
            name="orderStatus"
            value="en preparacion"
            checked={selectedStatus === 'en preparacion'}
            onChange={handleStatusChange}
          />
          <label htmlFor="en preparacion">En preparación ({countOrders('en preparacion')})</label>
       
          <input
            className='l-config'
            type="radio"
            id="para retirar"
            name="orderStatus"
            value="para retirar"
            checked={selectedStatus === 'para retirar'}
            onChange={handleStatusChange}
          />
          <label htmlFor="para retirar">Para retirar ({countOrders('para retirar')})</label>


          <input
            className='l-config'
            type="radio"
            id="en delivery"
            name="orderStatus"
            value="en delivery"
            checked={selectedStatus === 'en delivery'}
            onChange={handleStatusChange}
          />
          <label htmlFor="en delivery">Para delivery ({countOrders('en delivery')})</label>

          <input
          className='l-config'
            type="radio"
            id="cancelado"
            name="orderStatus"
            value="cancelado"
            checked={selectedStatus === 'cancelado'}
            onChange={handleStatusChange}
          />
          <label htmlFor="cancelado">Cancelado ({countOrders('cancelado')})</label>

          <input
          className='l-config'
            type="radio"
            id="finalizado"
            name="orderStatus"
            value="finalizado"
            checked={selectedStatus === 'finalizado'}
            onChange={handleStatusChange}
          />
          <label htmlFor="finalizado">Finalizado ({countOrders('finalizado')})</label>
        
          
        
        
        </div>
  
        {/* Desplegable de órdenes */}
       
       
       {selectedStatus === "finalizado" ? (
        <>
        
      
<div>
          <h3>Seleccionar orden:</h3>
          <select value={selectedOrder} onChange={handleOrderSelect}>
            <option value="">Seleccionar orden...</option>
            
            
            {filteredOrders
               .filter(order => order.estado === selectedStatus && order.selectedBranch === selectedBranchFilter)
               .map(order => (
                <option key={order.uid} value={order.uid}>
                  Orden #{order.uid} {order.firstName} {order.lastName} 
                </option>
              ))}
          </select>
        </div> 
        <ExportOrdersButton/>
        </>   
      ): ( 

<div>
          <h3>Seleccionar orden:</h3>
          <select value={selectedOrder} onChange={handleOrderSelect}>
            <option value="">Seleccionar orden...</option>
            
            
            {filteredOrders
               .filter(order => order.estado === selectedStatus && order.selectedBranch === selectedBranchFilter)
               .map(order => (
                <option key={order.uid} value={order.uid}>
                  Orden #{order.uid} {order.firstName} {order.lastName} 
                </option>
              ))}
          </select>
        </div>


       ) }
        
  
{/* Mostrar información de la orden seleccionada solo si está en "en preparacion" */}
         {selectedOrder && <OrderDetails key={key} order={selectedOrder} combos={combosArray} />}
  

{selectedOrder && (selectedOrder.estado === 'cancelado') && (
        <div>
                      <Button variant="danger" onClick={handleCancelOrder}>Cancelar Orden</Button>
        </div>
      )}


{(selectedOrder.estado === 'finalizado') && (
        <div>
                      <ExportOrdersButton/>
        </div>
      )}


       <div>
        <br></br>
          <label htmlFor="status">Seleccionar estado:</label>
          <select id="status" value={newStatus} onChange={(e) => setNewStatus(e.target.value)}>
            <option value="pendiente">Pendiente</option>
            <option value="en preparacion">En preparación</option>
            <option value="para retirar">Para retirar</option>
            <option value="en delivery">Para delivery</option>
            <option value="cancelado">Cancelado</option>
            <option value="finalizado">Finalizado</option>
            <option value="archivado">Archivar</option>

          </select>
         <Button className="btn-inicio" variant="secondary" onClick={handleStatusUpdate}>Modificar estado</Button>
        
        </div>
  
      </div>
    );
  
  
  }
  
  export default Order