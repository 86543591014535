import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button, Table, Container, Row, Col } from 'react-bootstrap';

import Logo from "../Logo/Logo"
const OrderDetails = ({ order, combos }) => {
  const printRef = useRef();

  // Calcular la cantidad total de carnes
  /* const totalCarnes = combos.reduce((total, combo) => {
    const cantidad = combo.selectedSize === "doble" ? 2 : 1;
    if (combo.carnesExtra === 0){
      return total + (cantidad * combo.quantity);  
    } else if (combo.carnesExtra>0) {
      return total + ((cantidad * combo.quantity)+combo.carnesExtra);
    }
    
  }, 0); */

  const totalCarnes = combos.reduce((total, combo) => {
    const cantidad = combo.selectedSize === "doble" ? 2 : 1;
    
    let carnesExtras = 0;
    if (combo.carnesExtra && typeof combo.carnesExtra === 'number') {
      carnesExtras = combo.carnesExtra * combo.quantity;
    }
  
    return total + (cantidad * combo.quantity) + carnesExtras;
  }, 0);
  

  const totalPanes = combos.reduce((total, combo) => {
    return total + combo.quantity;
  }, 0);

  // Calcular las salsas unificadas
  const acompañamiento = combos.reduce((acc, combo) => {
    const acompa = combo.acomp;
    if (acc[acompa]) {
      acc[acompa] += combo.quantity;
    } else {
      acc[acompa] = combo.quantity;
    }
    return acc;
  }, {});


  // Calcular las bebidas unificadas
  const bebidas = combos.reduce((acc, combo) => {
    const bebida = combo.selectedDrink;
    if (acc[bebida]) {
      acc[bebida] += combo.quantity;
    } else {
      acc[bebida] = combo.quantity;
    }
    return acc;
  }, {});

  // Calcular las salsas unificadas
  const salsas = combos.reduce((acc, combo) => {
    const salsa = combo.selectedDip;
    if (acc[salsa]) {
      acc[salsa] += combo.quantity;
    } else {
      acc[salsa] = combo.quantity;
    }
    return acc;
  }, {});


  /* handling print */
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });


  const getComboName = (combo) => {
    let comboName = combo.name;
  
    // Lógica para determinar el nombre del combo según la cantidad de carnes extras
    if (combo.selectedSize === 'simple') {
      switch (combo.carnesExtra) {
        case 1:
          comboName = 'Doble';
          break;
        case 2:
          comboName = 'Triple';
          break;
        case 3:
          comboName = 'Cuádruple';
          break;
        
        default:
          comboName = "Simple";
          break;
      }
    } else if (combo.selectedSize === 'doble') {
      switch (combo.carnesExtra) {
        case 1:
          comboName = 'Triple';
          break;
        case 2:
          comboName = 'Cuádruple';
          break;
        case 3:
          comboName = 'Quíntuple';
          break;
        
        default:
          comboName = "Doble";
          break;
      }
    }
  
    return comboName;
  };
  



  return (
    <>
      <Container ref={printRef}>
        <Logo />
        <h3>Información de la orden seleccionada:</h3>
      
        <Row>
          <Col><h5>Orden Nro: #{order.uid}</h5></Col>
          <Col><h5>Hora de pedido: {order.horaGeneracion}</h5> </Col> {/* Mostrar la cantidad total de panes */}
        </Row>
      
        <Row>

          <Col><h6>Cliente: {order.firstName} {order.lastName}</h6></Col>
          <Col><h6>Nro. de teléfono: {order.phone}</h6></Col>

        </Row>

        <Row>
        <Col><h5>Total de Carnes: {totalCarnes}</h5> </Col> {/* Mostrar la cantidad total de carnes */}
        <Col><h5>Total de Panes: {totalPanes}</h5> </Col> {/* Mostrar la cantidad total de panes */}
        
        </Row>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Producto</th>
              <th>Cantidad</th>
              <th>Tamaño</th>
              <th>Anotaciones</th>
            </tr>
          </thead>
          <tbody>
            {combos.map((combo, index) => (
              <tr key={`${combo.id}-${index}`}>
                <td>{combo.name}</td>
                <td>{combo.quantity}</td>
                <td>{getComboName(combo)}</td>
                <td>{combo.anotaciones}</td>
              </tr>
            ))}
          
          
       
          {Object.keys(acompañamiento).map((acompa, index) => (
            <tr key={index}>
              <td>{acompa} </td>
              <td>{acompañamiento[acompa]}</td>
              </tr>
          ))}
        
          {Object.keys(salsas).map((salsa, index) => (
            <tr key={index}>
              <td>{salsa}</td> 
              <td>{salsas[salsa]}</td>
            </tr>
          ))}
        
          {Object.keys(bebidas).map((bebida, index) => (
            <tr key={index}>
              <td>{bebida}</td> 
              <td> {bebidas[bebida]}</td>
            </tr>
          ))}
        
        


          </tbody>
          
        </Table>
        
        <Row>
          {order.deliveryOption === "Retiro en sucursal" && (
            <Col><h6>Retiro por sucursal: {order.selectedBranch}</h6></Col>
          )}
          {order.deliveryOption === "Envio a domicilio" && (
            <Col><h6>Domicilio de entrega: {order.deliveryAddress}</h6></Col>
          )}
          <Col><h6>Método de pago: {order.paymentMethod}</h6></Col>
          <Col><h6>Se cobra: ${order.precioFinal}</h6></Col>
        </Row>
        <Row>
          <Col><h6>Estado Actual: {order.estado}</h6></Col>
        </Row>
        
        
      </Container>
      <Button variant="success" onClick={handlePrint}>Imprimir Comanda</Button>
    </>
  );
}

export default OrderDetails;