import CollectionList from "./CollectionEditor"
import CollectionList1 from "./CollectionEditor1"
import CollectionList2 from "./CollectionEditor2"
import React , {useState} from 'react'
import { useUserContext } from "./UserContext"
import { Container, Row } from "react-bootstrap"
import Order from "./Order"
import "../Checkout/checkout.css";
import {Button} from "react-bootstrap"
import ExportOrdersButton from "./ExportOrdersButton"
import DeliverySelection from "./DeliverySelection"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const Inicio = () => {
  const { user } = useUserContext();
  const [showDrink, setShowDrink] = useState(false)
  const [showDips, setShowDips] = useState(false)
  const [showCombos, setShowCombos] = useState(false)
  const [showOrder, setShowOrder] = useState(false)
  const [showDeliverys, setShowDelivery] = useState(false)
  const [showCierre, setShowCierre] = useState(false)

  const handleShowDrink = () =>{
    setShowDrink(true)
    setShowDips(false)
    setShowCombos(false)
    setShowOrder(false)
    setShowDelivery(false)
    setShowCierre(false)
  }
  
  const handleShowDips = () =>{
    setShowDrink(false)
    setShowDips(true)
    setShowCombos(false)
    setShowOrder(false)
    setShowDelivery(false)
    setShowCierre(false)
  }
  
  const handleShowCombos = () =>{
    setShowDrink(false)
    setShowDips(false)
    setShowCombos(true)
    setShowOrder(false)
    setShowDelivery(false)
    setShowCierre(false)
  }

  const handleShowOrder = () =>{
    setShowDrink(false)
    setShowDips(false)
    setShowCombos(false)
    setShowOrder(true)
    setShowDelivery(false)
    setShowCierre(false)
  }

  const handleShowDelivery = () =>{
    setShowDrink(false)
    setShowDips(false)
    setShowCombos(false)
    setShowOrder(false)
    setShowDelivery(true)
    setShowCierre(false)
  }


  const handleShowCierre = () =>{
    setShowDrink(false)
    setShowDips(false)
    setShowCombos(false)
    setShowOrder(false)
    setShowDelivery(false)
    setShowCierre(true)
  }

  return (
    <>
    <Container>
<div className="back">
<h2>Bienvenido</h2> 
<Tabs
  defaultActiveKey="profile"
  id="justify-tab-example"
  className="mb-3"
  justify
>
  <Tab eventKey="home" title="Productos" variant="secondary">
    <Button className="btn-inicio" variant="secondary" onClick={handleShowDrink}>Bebidas</Button>
    <Button className="btn-inicio" variant="secondary" onClick={handleShowDips}>Salsas</Button>
    <Button className="btn-inicio" variant="secondary" onClick={handleShowCombos}>Combos</Button>
  </Tab>

  <Tab eventKey="ordenes" title="Ordenes">
    <Button className="btn-inicio" variant="secondary" onClick={handleShowOrder}>Buscar orden de app</Button>
    <Button className="btn-inicio" variant="secondary" onClick={handleShowDelivery}>Delivery</Button>
  </Tab>

  <Tab eventKey="contact" title="Cierre">
    <Button className="btn-inicio" variant="secondary" onClick={handleShowCierre}>Cierre del día</Button>
  </Tab>
</Tabs>

      {showDrink && <CollectionList/>}
      {showDips && <CollectionList1/>}
      {showCombos && <CollectionList2/>}
      {showOrder && <Order/>}
      {showDeliverys &&<DeliverySelection/>}
      {showCierre && <div>Cierre proximamente...
        <ExportOrdersButton/></div>}
      </div>
      </Container>
    </>
  )
}

export default Inicio
