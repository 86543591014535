import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import "../Checkout/checkout.css";
const ImageUploader = ({ onUploadComplete }) => {
  const [preview, setPreview] = useState(null);
  const [uploading, setUploading] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setPreview(URL.createObjectURL(file)); // Crear una URL de objeto local para previsualización

    const formData = new FormData();
    formData.append('files', file);

    setUploading(true);
    axios.post('https://pedir-pedir.p89nps.easypanel.host/uploads', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      const imageUrl = `https://pedir-pedir.p89nps.easypanel.host/uploads/${response.data.files[0]}`;
      onUploadComplete(imageUrl); // Llama a la función onUploadComplete con la URL de la imagen
    })
    .catch(error => {
      console.error('Error uploading files:', error);
    })
    .finally(() => {
      setUploading(false);
    });
  }, [onUploadComplete]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div>
        <label>Subir imagen del combo:</label>
        <br></br>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>Arrastra y suelta algunas imágenes aquí, o haz clic para seleccionar archivos</p>
      </div>
      {preview && (
        <div className="preview">
          <img src={preview} alt="preview" style={{ width: '200px', margin: '10px' }} />
        </div>
      )}
      {uploading && <p>Subiendo imagen...</p>}
    </div>
  );
};

export default ImageUploader;
