import React from 'react'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ItemDetail from '../ItemDetail/ItemDetail'
import axios from 'axios';

const ItemDetailContainer = () => {
  const [item, setItem] = useState([])
  const {id} = useParams()
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://pedir-pedir.p89nps.easypanel.host/item/${id}`);
        setItem(response.data);
      } catch (error) {
        console.error('Error al obtener el producto:', error);
      }
    };
  
    fetchData();
  }, [id]);


  return (
    <div className='container'>

<div className='row'>
      <ItemDetail item= {item} />
      </div>

    </div>
  )
}


export default ItemDetailContainer