import React from 'react'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ItemList from '../ItemList/ItemList'
import axios from 'axios';

const ItemsListContainer = () => {
  
  const [item, setItem] = useState([])
  const {id} = useParams()
  
  useEffect(() => {
    axios.get('https://pedir-pedir.p89nps.easypanel.host/api/productos') //https://app-brb.aqw3ar.easypanel.host/api/productos
      .then(response => {
        // Aquí puedes manejar los datos obtenidos
        setItem(response.data);

        console.log(response.data);
        console.log("DATOS OBTENIDOS")
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);  
 
  return (
    <div className='container'>

<div className='row'>
      <ItemList item= {item} />
      </div>

    </div>
  )
}

export default ItemsListContainer