import { useState, useEffect } from 'react';
import React from 'react';
import { Table, Row, Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import axios from 'axios';

const DeliverySelection = () => {
  const [orders, setOrders] = useState([]);
  const [showAssign, setShowAssign] = useState(false);
  const [showAssignOrder, setShowAssignOrder] = useState(false);
  const [selectedBranchFilter, setSelectedBranchFilter] = useState('');
  const [deliveryStatus, setDeliveryStatus] = useState({});

  useEffect(() => {
    // Fetch orders from the server
    const fetchOrders = async () => {
      try {
        const response = await axios.get('https://pedir-pedir.p89nps.easypanel.host/orders/get');
        setOrders(response.data);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };
    
    fetchOrders();
  }, []);

  const handleStatusChange = (orderId, newStatus) => {
    setDeliveryStatus((prevState) => ({
      ...prevState,
      [orderId]: newStatus,
    }));
  };

  const calculateTotals = () => {
    let totalRendido = 0;
    let totalRestante = 0;

    orders
      .filter(order => order.estado === 'en delivery')
      .forEach((order) => {
        const status = deliveryStatus[order.uid];
        if (status === 'Finalizado') {
          totalRendido += order.precioFinal; // Assuming 'total' is the amount to be rendered for each order
        } else {
          totalRestante += order.precioFinal;
        }
      });

    return { totalRendido, totalRestante };
  };

  const totals = calculateTotals();

  return (
    <div>
      <label>Seleccione sucursal:</label>
      <select value={selectedBranchFilter} onChange={(e) => setSelectedBranchFilter(e.target.value)}>
        <option value="">Seleccionar sucursal...</option>
        <option value="Castelli 3067">Castelli</option>
        <option value="Patagones 305">Patagones</option>
      </select>
      <br></br>
      <br></br>
      <label>Seleccione Delivery:</label>
      <select>
        <option value="">Seleccionar sucursal...</option>
        <option value="Castelli 3067">Castelli</option>
        <option value="Patagones 305">Patagones</option>
      </select>
      <br></br>
      <label>Delivery:</label><h4>Fulano</h4>
      <Button onClick={() => setShowAssign(true)}>Asignar/ quitar orden</Button>
      <br></br>
      <br></br>
      {showAssign && (
        <>
          <label>Ordenes listas para enviar:</label>
          <select>
            <option value="">Seleccionar orden...</option>
            {orders.filter(order => order.estado === 'en delivery').map(order => (
              <option key={order.uid} value={order.uid}>
                Orden #{order.uid}
              </option>
            ))}
          </select>
          <Button>Asignar orden a delivery</Button>
          <Button variant='danger'>Quitar orden a delivery</Button>
          <br></br>
          <br></br>
        </>
      )}
      <Button onClick={() => setShowAssignOrder(true)}>Ver ordenes asignadas</Button>
      <br></br>
      <br></br>
      {showAssignOrder && (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Nro. de orden</th>
                <th>Estado</th>
                <th>Domicilio</th>
                <th>Monto a rendir</th>
                <th>Rendido</th>
              </tr>
            </thead>
            <tbody>
              {orders.filter(order => order.estado === 'en delivery').map(order => (
                <tr key={order.uid}>
                  <td>{order.uid}</td>
                  <td>{deliveryStatus[order.uid] || 'En viaje'}</td>
                  <td>{order.deliveryAddress}</td>
                  <td>{order.precioFinal}</td>
                  <td>
                    <input
                      type='checkbox'
                      checked={deliveryStatus[order.uid] === 'Finalizado'}
                      onChange={(e) => handleStatusChange(order.uid, e.target.checked ? 'Finalizado' : 'En viaje')}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row>
            <Col>
              <h3>Resta rendir: ${totals.totalRestante}</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Total rendido: ${totals.totalRendido}</h3>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default DeliverySelection;
