import React, { useState } from 'react';

const MedallonesDropdown = ({ onMedallonesExtraChange }) => {
  const [medallonesExtras, setMedallonesExtras] = useState(0);

  const handleMedallonesExtraChange = (event) => {
    const value = parseInt(event.target.value);
    setMedallonesExtras(value);
    onMedallonesExtraChange(value); // Pasar el valor de medallones extra de vuelta al padre
  };

  return (
    <div>
      <label>Medallones Extra:</label>
      <select value={medallonesExtras} onChange={handleMedallonesExtraChange}>
        <option value={0}>0</option>
        <option value={1}>1</option>
        <option value={2}>2</option>
        <option value={3}>3</option>
      </select>
    </div>
  );
};

export default MedallonesDropdown;
