import React, { useState, useEffect } from 'react';
import "../Checkout/checkout.css";
import axios from 'axios';
import { Button, Spinner, Toast } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import "../Login/CollectionEditor.css";

const ManualOrder = () => {
    const [burguer, setItem] = useState([]);
    const [selectedBurguer, setSelectedBurguer] = useState("");
    const [newName, setNewName] = useState('');
    const [newDescription, setNewDescription] = useState(selectedBurguer.description || '');
    const [newSimple, setNewSimple] = useState(selectedBurguer.simple || '');
    const [newDoble, setNewDoble] = useState(selectedBurguer.doble || '');
    const [loading, setLoading] = useState(false); // Estado para mostrar spinner
    const [showToast, setShowToast] = useState(false); // Estado para el toast de éxito
    const [toastMessage, setToastMessage] = useState(""); // Mensaje del toast
    const [error, setError] = useState(null); // Estado para manejar errores
    
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://pedir-pedir.p89nps.easypanel.host/api/productos`);
          setItem(response.data);
        } catch (error) {
          console.error('Error al obtener el producto:', error);
        }
      };
    
      fetchData();
    }, []);
   
    const handleNameUpdate = async () => {
      setLoading(true);
      setError(null);
      try {
        await axios.put(`https://pedir-pedir.p89nps.easypanel.host/item/${selectedBurguer.id}/name`, { newName });
        console.log('Nombre del combo actualizado correctamente en MySQL');
        setSelectedBurguer({ ...selectedBurguer, name: newName });
        setToastMessage("Nombre actualizado correctamente.");
        setShowToast(true);
      } catch (error) {
        console.error('Error al actualizar el nombre en MySQL:', error);
        setError("Error al actualizar el nombre. Intente nuevamente.");
      } finally {
        setLoading(false);
      }
    };

    const handleDescriptionUpdate = async () => {
      setLoading(true);
      setError(null);
      try {
        await axios.put(`https://pedir-pedir.p89nps.easypanel.host/item/${selectedBurguer.id}/description`, { newDescription });
        console.log('Descripción actualizada correctamente en MySQL');
        setToastMessage("Descripción actualizada correctamente.");
        setShowToast(true);
      } catch (error) {
        console.error('Error al actualizar la descripción en MySQL:', error);
        setError("Error al actualizar la descripción. Intente nuevamente.");
      } finally {
        setLoading(false);
      }
    };

    const handleSimpleUpdate = async () => {
      setLoading(true);
      setError(null);
      try {
        await axios.put(`https://pedir-pedir.p89nps.easypanel.host/item/${selectedBurguer.id}/simple`, { newSimple });
        console.log('Precio simple actualizado correctamente en MySQL');
        setToastMessage("Precio simple actualizado correctamente.");
        setShowToast(true);
      } catch (error) {
        console.error('Error al actualizar el precio simple en MySQL:', error);
        setError("Error al actualizar el precio simple. Intente nuevamente.");
      } finally {
        setLoading(false);
      }
    };

    const handleDobleUpdate = async () => {
      setLoading(true);
      setError(null);
      try {
        await axios.put(`https://pedir-pedir.p89nps.easypanel.host/item/${selectedBurguer.id}/doble`, { newDoble });
        console.log('Precio doble actualizado correctamente en MySQL');
        setToastMessage("Precio doble actualizado correctamente.");
        setShowToast(true);
      } catch (error) {
        console.error('Error al actualizar el precio doble en MySQL:', error);
        setError("Error al actualizar el precio doble. Intente nuevamente.");
      } finally {
        setLoading(false);
      }
    };

    const eliminarCombo = async () => {
      setLoading(true);
      setError(null);
      try {
        await axios.delete(`https://pedir-pedir.p89nps.easypanel.host/item/${selectedBurguer.name}`);
        console.log("Combo eliminado correctamente");
        setToastMessage("Combo eliminado correctamente.");
        setShowToast(true);
      } catch (error) {
        console.error("Error al eliminar combo:", error);
        setError("Error al eliminar el combo. Intente nuevamente.");
      } finally {
        setLoading(false);
      }
    };

    const handleBurguer = event => {
      const selectedComboName = event.target.value;
      const selectedCombo = burguer.find(combo => combo.name === selectedComboName);
      setSelectedBurguer(selectedCombo);
    };

    return (
      <div>
        <label className='underline' value=""><b>Seleccione combo</b></label>
        <br></br>
        <select value={selectedBurguer} onChange={handleBurguer}>
          <option value="">Seleccione un combo</option>
          {burguer.map(br => (
            <option key={br.id} value={br.name}>
              {br.name}
            </option>
          ))}
        </select>
        <br></br>
        
        {selectedBurguer &&
        <> 
          <p>Seleccionaste: <b>{selectedBurguer.name}</b></p>
          {burguer.filter(br => br.name === selectedBurguer.name).map(br => (
            <div key={br.id}>
              <p>Nombre: {br.name}</p>
              <Button variant='danger' onClick={eliminarCombo} disabled={loading}>
                {loading ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    {' '}Cargando...
                  </>
                ) : (
                  'Eliminar combo'
                )}
              </Button>
              <br></br>
              <br></br>
              
              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header><h4>Modificar combo</h4></Accordion.Header>
                  <Accordion.Body>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Nombre</Accordion.Header>
                        <Accordion.Body>
                          <input
                            placeholder='Nuevo nombre'
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                          />
                          <Button className="btn-inicio" variant='secondary' onClick={handleNameUpdate} disabled={loading}>
                            {loading ? (
                              <>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                {' '}Cargando...
                              </>
                            ) : (
                              'Modificar nombre'
                            )}
                          </Button>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Descripción</Accordion.Header>
                        <Accordion.Body>
                          <p>Descripción actual: {br.description}</p>
                          <textarea
                            placeholder='Nueva descripción'
                            value={newDescription}
                            onChange={(e) => setNewDescription(e.target.value)}
                          />
                          <br></br>
                          <Button className="btn-inicio" variant='secondary' onClick={handleDescriptionUpdate} disabled={loading}>
                            {loading ? (
                              <>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                {' '}Cargando...
                              </>
                            ) : (
                              'Modificar descripción'
                            )}
                          </Button>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>Precio simple</Accordion.Header>
                        <Accordion.Body>
                          <p>Precio simple actual: ${br.simple}</p>
                          <input
                            placeholder='Nuevo precio simple'
                            value={newSimple}
                            onChange={(e) => setNewSimple(e.target.value)}
                          />
                          <Button className="btn-inicio" variant='secondary' onClick={handleSimpleUpdate} disabled={loading}>
                            {loading ? (
                              <>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                {' '}Cargando...
                              </>
                            ) : (
                              'Modificar precio simple'
                            )}
                          </Button>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>Precio doble</Accordion.Header>
                        <Accordion.Body>
                          <p>Precio doble actual: ${br.doble}</p>
                          <input
                            placeholder='Nuevo precio doble'
                            value={newDoble}
                            onChange={(e) => setNewDoble(e.target.value)}
                          />
                          <Button className="btn-inicio" variant='secondary' onClick={handleDobleUpdate} disabled={loading}>
                            {loading ? (
                              <>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                {' '}Cargando...
                              </>
                            ) : (
                              'Modificar precio doble'
                            )}
                          </Button>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          ))}
        </>
        }
        
        <br></br>
        {/* Toast para mostrar mensajes de éxito */}
        <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>

        {/* Mensaje de error en caso de fallo */}
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div> 
    );
}

export default ManualOrder;
