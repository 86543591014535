import React from 'react'
import {Link} from 'react-router-dom'
import "../Item/item.css";
const Item = ({item}) => {
  return (
 <Link to={'/item/' + item.id} className='text-decoration-none'>
  <div className='card'  >
 <img className="card-img-top" src={item.image} alt="Productos de la web" />

  <div className='card-body'> 
    <h5 className='card-title'>- Combo: {item.name}</h5>
    <p><b>Clickea para Ampliar info!</b></p>
  </div>
  </div>
 </Link>
  )
}

export default Item