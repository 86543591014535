import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import "../Checkout/checkout.css";
import { Button, Spinner, Toast } from 'react-bootstrap';


const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);  // Estado para el spinner
    const [error, setError] = useState(null);  // Estado para el manejo de errores
    const history = useNavigate();  // Para redirigir al usuario
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);  // Mostrar el spinner cuando se inicia la solicitud
      setError(null);  // Reiniciar el error al intentar de nuevo
  
      try {
        // Realizar una solicitud al servidor para verificar las credenciales del usuario
        const response = await axios.post('https://pedir-pedir.p89nps.easypanel.host/login', {
          email,
          password
        });
  
        // Si el inicio de sesión es exitoso, redirigir al usuario a la página correspondiente
        history('/collection-editor');
      } catch (error) {
        // Manejar error
        setError(error.response ? error.response.data.error : 'Error en la conexión');
      } finally {
        setLoading(false);  // Ocultar el spinner cuando la solicitud finaliza
      }
    };
  
    return (
      <div fluid className='back'>
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <input
            name='text'
            type='text'
            placeholder='Usuario'
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
          />
          <br></br>
          <br></br>
          <input
            name='password'
            type='password'
            placeholder="Password"
            value={password}
            onChange={(ev) => setPassword(ev.target.value)}
          />
          <br></br>
          <br></br>
          <div>
            <Button className="btn-inicio" variant="secondary" type="submit" disabled={loading}>
              {loading ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  {' '}Cargando...
                </>
              ) : (
                'Login'
              )}
            </Button>
          </div>
        </form>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>
    );
  };

export default Login;
