import React, { useState } from 'react';
import DrinksDropdown from '../Drinks/DrinksDropdown';
import axios from 'axios';
import { useCartContext } from '../Context/CartContext';
import "../Checkout/checkout.css";
import { Button, Spinner, Toast } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import "../Login/CollectionEditor.css";

const CollectionList = () => {
    const [nombre, setNombre] = useState("");
    const [tamaño, setTamaño] = useState("");
    const { selectedDrink } = useCartContext();
    const [loading, setLoading] = useState(false);  // Estado para mostrar spinner
    const [showToast, setShowToast] = useState(false);  // Estado para el toast de éxito
    const [toastMessage, setToastMessage] = useState("");  // Mensaje del toast
    const [error, setError] = useState(null);  // Estado para manejar errores

    const agregarBebida = async () => {
        setLoading(true);
        setError(null);  // Limpiar error previo

        try {
            const response = await axios.post('https://pedir-pedir.p89nps.easypanel.host/drinks', {
                name: nombre,
                tamaño: tamaño
            });
            console.log("Bebida agregada correctamente:", response.data);
            setNombre("");
            setTamaño("");
            setToastMessage("Bebida agregada correctamente.");
            setShowToast(true);  // Mostrar el toast de éxito
        } catch (error) {
            console.error("Error al agregar bebida:", error);
            setError("Error al agregar bebida. Intente nuevamente.");
        } finally {
            setLoading(false);  // Ocultar el spinner
        }
    };

    const eliminarBebida = async () => {
        setLoading(true);
        setError(null);  // Limpiar error previo

        try {
            const response = await axios.delete(`https://pedir-pedir.p89nps.easypanel.host/drinks/${selectedDrink}`);
            console.log("Bebida eliminada correctamente:", response.data.name);
            setToastMessage("Bebida eliminada correctamente.");
            setShowToast(true);  // Mostrar el toast de éxito
        } catch (error) {
            console.error("Error al eliminar bebida:", error);
            setError("Error al eliminar bebida. Intente nuevamente.");
        } finally {
            setLoading(false);  // Ocultar el spinner
        }
    };

    return (
        <>
            <Accordion flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header><h4>Agregar bebida:</h4></Accordion.Header>
                    <Accordion.Body>
                        <input
                            placeholder='Ingrese nombre de bebida'
                            value={nombre}
                            onChange={(e) => setNombre(e.target.value)}
                        />
                        <br /><br />
                        <input
                            placeholder='Ingrese tamaño de bebida'
                            value={tamaño}
                            onChange={(e) => setTamaño(e.target.value)}
                        />
                        <br /><br />
                        <Button variant="success" onClick={agregarBebida} disabled={loading}>
                            {loading ? (
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    {' '}Cargando...
                                </>
                            ) : (
                                'Agregar bebida'
                            )}
                        </Button>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><h4>Eliminar bebida:</h4></Accordion.Header>
                    <Accordion.Body>
                        <DrinksDropdown />
                        <Button variant="danger" onClick={eliminarBebida} disabled={loading}>
                            {loading ? (
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    {' '}Cargando...
                                </>
                            ) : (
                                'Eliminar bebida'
                            )}
                        </Button>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            {/* Mostrar Toast de éxito */}
            <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                <Toast.Header>
                    <strong className="me-auto">Notificación</strong>
                    <small>Justo ahora</small>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>

            {/* Mostrar error si existe */}
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </>
    );
};

export default CollectionList;
