import React, { useEffect, useState } from "react";
import "../Item/item.css";
import toast, { Toaster } from 'react-hot-toast';
import Button from 'react-bootstrap/Button';
import 'react-toastify/dist/ReactToastify.css';

const ItemCount = ({ initial, stock, onAdd, disabled }) => {
  const [count, setCount] = useState(parseInt(initial));

  const decrease = () => {
    setCount(count - 1);
  };

  const increase = () => {
    setCount(count + 1);
  };

  useEffect(() => {
    setCount(parseInt(initial));
  }, [initial]);

  const notify = () => {
    console.log('Notificación mostrada');
    toast.success('Combo agregado!')
  }

  return (
    <div className="counter">
      <button className="btn-left" disabled={count <= 1} onClick={decrease}>
        -
      </button>
      <span>{count}</span>
      <button className="btn-right" disabled={count >= stock} onClick={increase}>
        +
      </button>
      <br></br>
      <br></br>
      <div>
        <Button variant="primary" disabled={stock <= 0 || disabled} onClick={() => {
          notify();
          onAdd(count);
        }}>
          Agregar al carrito
        </Button>
        <div>
          <Toaster
            position="bottom-right"
            reverseOrder={false} />
        </div>
      </div>
    </div>
  );
};

export default ItemCount;
