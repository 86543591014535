import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useCartContext } from '../Context/CartContext';
import ItemCart from '../ItemCart/ItemCart';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import imgCarro from "../../Imagenes/carrovacio.png"
import imgCarro1 from "../../Imagenes/carrolleno.png"
import toast, { Toaster } from 'react-hot-toast';
import "../CartWidget/CartWidget.css";


const CartWidget = ({selectedSize, acomp}) => {
    const { totalProducts, cart, totalPrice } = useCartContext();
    const [show, setShow] = useState(false);
    const [iconCart, setIconCart] = useState(totalProducts === 0 ? imgCarro : imgCarro1);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleIcon = () => {
        if (totalProducts() === 0) {
            setIconCart(imgCarro);
        } else {
            setIconCart(imgCarro1);
        }
    };
    
    useEffect(() => {
        handleIcon();
    }, [totalProducts]);    
    return (
        <>  
            <div className="estilo-cart mood" onClick={handleShow}>

                <img src={iconCart} style={{width:"40px", height:"40px"}} />
                <span className='count' id="count">{totalProducts() || cart}</span>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Pedido:</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                    {cart.map((item) => (
                        <ItemCart key={item.id} item={item} selectedSize={selectedSize} acomp={acomp} />
                    ))}
                    
      <Toaster
	  position="top-center"
	  reverseOrder={false} />
    
                </Modal.Body>
                <Modal.Footer>
                <p><b>Total del pedido: ${totalPrice()}</b></p>
                    <Button variant="secondary" onClick={handleClose}>
                        Volver
                    </Button>
                    <Link to="/checkout">
                        <Button variant="dark" onClick={handleClose}>
                            Realizar pedido
                        </Button>
                    </Link>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CartWidget;
