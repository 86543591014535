import React, { useState } from 'react';
import { useCartContext } from '../Context/CartContext';
import { Button } from 'react-bootstrap';
import axios from 'axios';

const Message = ({ firstName, lastName, paymentMethod, deliveryOption, selectedBranch, deliveryAddress, phone, zona, formIsValid }) => {
    const { cart, totalPrice } = useCartContext();


    //aca irian los numeros de las sucursales.
    const handleWhatsAppShare = async () => {
        let precioFinal = totalPrice(); // Calcular el precio final al momento de enviar el mensaje

        if (deliveryOption === 'Envio a domicilio') {
          precioFinal += 700;
          console.log("PRECIO TOTAL" + precioFinal)
          console.log("ZONA SELECCIONADA:"+zona)
        }

      let phoneNumber = null;
      if (deliveryOption === "Retiro en sucursal"){ 
      if( selectedBranch==="Castelli 3067"){
          phoneNumber="2235985787";
          console.log(selectedBranch)
          console.log(zona)
          console.log(phoneNumber)
      } else if (selectedBranch==="Patagones 305"){
          phoneNumber=/* "2236034966" */"2235985787";
          console.log(selectedBranch)
          console.log(zona)
          console.log(phoneNumber)
      }
    }
    if (deliveryOption === "Envio a domicilio"){ 
        if( zona==="Zona sucursal Castelli 3067"){
            phoneNumber="2235985787";
            selectedBranch="Castelli 3067";
            console.log(selectedBranch)
            console.log(zona)
            console.log(phoneNumber)
        } else if (zona==="Zona sucursal Patagones 305"){
            phoneNumber=/* "2236034966" */"2235985787";
            selectedBranch="Patagones 305";
            console.log(selectedBranch)
            console.log(zona)
            console.log(phoneNumber)
        }
      }



      const message = buildMessage(cart, precioFinal); // Pasar el precio final a la función buildMessage
      const currentTime = new Date();  
      const orderDetails = {
            firstName,
            lastName,
            phone,
            deliveryOption,
            deliveryAddress,
            selectedBranch,
            paymentMethod,
            estado: "pendiente",
            combos: cart,
            precioFinal, // Incluir el precio final en los detalles de la orden
            fechaGeneracion: currentTime.toISOString().slice(0, 10), // Fecha en formato 'yyyy-mm-dd'
            horaGeneracion: currentTime.toLocaleTimeString('es-AR', { hour12: false }), // Hora en formato 'hh:mm:ss'

        };

        try {
            await axios.post('https://pedir-pedir.p89nps.easypanel.host/orders', orderDetails);
            console.log('Detalles de la orden enviados correctamente');
        } catch (error) {
            console.error('Error al enviar los detalles de la orden:', error);
        }

        
        if (phoneNumber) {
            const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
            window.open(whatsappUrl, '_blank');
        } else {
            console.error("Número de teléfono no especificado para la zona o sucursal seleccionada");
        }
    };

    const buildMessage = (cart, precioFinal) => {
        let message = `Hola buenas noches! mi nombre es *${firstName} ${lastName}*, te detallo el pedido que quiero realizar:\n`;

        cart.forEach((item, index) => {
            message += `\n*- Combo nro:* ${index + 1}:\n`;
            message += `*- Combo:* ${item.name}\n`;
            message += `*- Cantidad:* ${item.quantity}\n`;
            message += `*- Tamaño:* ${item.selectedSize}\n`;
            message += `*- Acompañamiento:* ${item.acomp}\n`;
            message += `*- Salsa:* ${item.selectedDip}\n`;
            message += `*- Bebida:* ${item.selectedDrink}\n`;
            message += `*- Medallones extras:* ${item.extras.medallones}\n`;
            message += `*- Anotaciones:* ${item.anotaciones}\n`;
            message += `*-Precio:* $${item.price}\n`;
        });

        if (deliveryOption === 'Envio a domicilio') {
            message += `*- Y necesito que lo envíen a:* ${deliveryAddress}\n`;
        } else {
            message += `*- Y voy a ir a retirarlo a:* ${selectedBranch}\n`;
        }

        message += `\n*- El metodo de pago es:* ${paymentMethod}\n`;
        message += "*Precio total a pagar:* $";
        message += precioFinal;
        message += `\n*Muchas gracias!!*`;

        return message;
    };

    return (
        <div>
            <Button disabled={!formIsValid} variant="success" onClick={handleWhatsAppShare}>Compartir Pedido por WhatsApp <i className="bi bi-whatsapp bwp"></i></Button>
        </div>
    );
};

export default Message;
