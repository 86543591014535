import React, { useState, useContext } from 'react';
const CartContext = React.createContext('');
export const useCartContext = () => useContext(CartContext);

const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [selectedSize, setSelectedSize] = useState("doble");
  const [selectedDrink, setSelectedDrink] = useState("");  
  const [selectedDip, setSelectedDip] = useState("");
  const [anotaciones, setAnotacion] = useState("")
  
  /* const addProduct = (item, quantity, selectedSize, price, acomp, selectedDrink, selectedDip, anotaciones) => {
    const updatedCart = [...cart];
  
    // Agregamos un nuevo artículo al carrito con la información del combo y acompañamiento seleccionados
    updatedCart.push({ ...item, quantity, selectedSize, price, acomp, selectedDrink, selectedDip, anotaciones });
  
    // Actualizamos el estado del carrito
    setCart(updatedCart);
  }; */
    
  const addProduct = (item, quantity, selectedSize, price, acomp, selectedDrink, selectedDip, anotaciones, extras) => {
    let precioFinal = price;
  
    // Lógica para calcular precio con medallones extra
    if (extras && extras.medallones > 0) {
      // Supongamos que cada medallón extra tiene un costo adicional de $50
      precioFinal += extras.medallones * 1500;
    }
  
    const updatedCart = [...cart];
    updatedCart.push({ ...item, quantity, selectedSize, price: precioFinal, acomp, selectedDrink, selectedDip, anotaciones, extras });
  
    setCart(updatedCart);
  };
  

 

  const totalPrice = () => {
    return cart.reduce((prev, act) => prev + act.quantity * act.price, 0);
  
  };

  const totalProducts = () =>
    cart.reduce(
      (acumulador, productoActual) => acumulador + productoActual.quantity,
      0
    );

  const clearCart = () => setCart([]);

  const isInCart = (id) =>
    cart.find((product) => product.id === id) ? true : false;
    
  const removeProduct = (id, selectedSize, acomp, selectedDrink, selectedDip) => {
      if (cart.length === 1 && cart[0].id === id) {
        // Si solo hay un producto en el carrito y es el que queremos eliminar, limpiar el carrito
        setCart([]);
      } else {
        // Si hay más de un producto o el que queremos eliminar no es el único en el carrito, filtrar el producto
          setCart(cart.filter((product) => 
            product.id !== id ||
            product.selectedSize !== selectedSize ||
            product.acomp !== acomp ||
            product.selectedDrink !== selectedDrink ||
            product.selectedDip !== selectedDip
          ));
        
      }
    };
    

  return (
    <CartContext.Provider
      value={{
        clearCart,
        isInCart,
        removeProduct,
        addProduct,
        totalPrice,
        totalProducts,
        cart,
        selectedSize, 
        setSelectedSize,
        selectedDrink,
        setSelectedDrink,
        selectedDip,
        setSelectedDip,
        anotaciones,
        setAnotacion,
      

      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;