import React, { useState, useEffect } from 'react';
import ItemCount from '../ItemCount/ItemCount';
import { Link } from 'react-router-dom';
import { useCartContext } from '../Context/CartContext';
import '../ItemDetail/ItemDetail.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import "../ItemDetail/ItemDetail.css"
import DrinksDropdown from '../Drinks/DrinksDropdown';
import Dips from '../Dips/Dips';
import Button from 'react-bootstrap/Button';
import MedallonesDropdown from '../Extra/MedallonesDropdown';
const ItemDetail = ({ item }) => {
  const [price, setPrice] = useState("doble");
  const { addProduct, selectedSize, setSelectedSize, selectedDrink, selectedDip, anotaciones, setAnotacion } = useCartContext();
  const [goToCart, setGoToCart] = useState(false);
  const [acomp, setAcomp] = useState("Papas fritas");
  const [formComplete, setFormComplete] = useState(false);
  const [precioExtras, setPrecioExtras] = useState(0); // Estado para almacenar el costo adicional de los medallones extra
  const [medallonesExtras, setMedallonesExtras] = useState(0);

  useEffect(() => {
    // Verifica si todos los campos están completos
    checkFormCompletion();
    actualizarPrecio();
  }, [selectedSize, acomp, selectedDrink, selectedDip, anotaciones, precioExtras]);


  const actualizarPrecio = () => {
    let precioBase = selectedSize === 'simple' ? item.simple : item.doble;
 /*    precioBase += precioExtras; // Sumar el costo adicional de los medallones extra */
    setPrice(precioBase);
  };

  // Función para verificar si todos los campos están seleccionados
  const checkFormCompletion = () => {
    const isFormComplete =
      selectedSize !== '' &&
      acomp !== '' &&
      selectedDrink !== '' &&
      selectedDip !== ''/*  &&
      anotaciones !== '' */;

    setFormComplete(isFormComplete);
  };

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };

  const handleAcompChange = (event) => {
    setAcomp(event.target.value);
  };

  /* const handlePrecioExtrasChange = (precio) => {
    setPrecioExtras(precio);
  }; */

  const handleMedallonesExtraChange = (value) => {
    setMedallonesExtras(value);
    // Calcular el precio final sumando el costo de medallones extra (por ejemplo, $50 por medallón)
    const precioMedallonesExtra = value * 1500; // Ajusta según tu lógica de precios
    setPrecioExtras(precioMedallonesExtra);
  };

  const onAdd = (quantity) => {
    setGoToCart(true);
    addProduct(item, quantity, selectedSize, price, acomp, selectedDrink, selectedDip, anotaciones, { medallones: medallonesExtras });
    console.log('El combo es: ' + selectedSize + " El acompañamiento es:" + acomp + " La bebida es" + selectedDrink + " y la salsa es:" + selectedDip + ' y el precio es: ' + price);
  };

  return (
    <Container>
      <Row>
        <div className='card row estilo'>
          <Col>
            <img className='card-img-top' src={item.image} alt='' />
          </Col>
          <Col>
            <div className='card-body'>
              <h5 className='card-title'>- Combo: {item.name}</h5>
              <p>
                <b>- Descripcion:</b> {item.description}
              </p>
              <b className='underline'>Tamaño</b>
              <br></br>
              <label className='tmñ-label'>
                <input
                  type='radio'
                  value={item.size}
                  checked={selectedSize === 'simple'}
                  onChange={handleSizeChange}
                />
                Simple
              </label>
              <label>
                <input
                  type='radio'
                  value={item.size1}
                  checked={selectedSize === 'doble'}
                  onChange={handleSizeChange}
                />
                Doble
              </label>
              <br></br>
              <br></br>
              <b className='underline'>Acompañamiento</b>
              <br></br>
              <label className='tmñ-label'>
                <input
                  type='radio'
                  value={item.acompañamiento}
                  checked={acomp === 'Papas fritas'}
                  onChange={handleAcompChange}
                />
                Papas fritas
              </label>
              <label>
                <input
                  type='radio'
                  value={item.acompañamiento1}
                  checked={acomp === 'Aros de cebolla'}
                  onChange={handleAcompChange}
                />
                Aros de cebolla
              </label>
              <br></br>
              <br></br>
              <DrinksDropdown />
              <Dips />
              <br></br>
              <MedallonesDropdown onMedallonesExtraChange={handleMedallonesExtraChange} /> {/* Agregar el componente MedallonesDropdown */}
              <br></br>
              <b className='underline'>Anotaciones</b>
              <br></br>
              <textarea placeholder='Ingrese anotaciones' value={anotaciones} onChange={(e) => setAnotacion(e.target.value)} />
              <p>
                <br></br>
                <h3><b>- Precio: ${ selectedSize === 'simple' ? item.simple+precioExtras : item.doble+precioExtras}</b></h3>
              </p>
            </div>
          </Col>
          <div>
            <ItemCount stock={10} initial={1} onAdd={onAdd} disabled={!formComplete} />
            <br></br>
            <Link to='/home'>
              <Button variant="secondary">
                Volver
              </Button>
            </Link>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default ItemDetail;
