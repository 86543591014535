import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useCartContext } from '../Context/CartContext';
import axios from 'axios';

const DrinksDropdown = () => {
    const [drinks, setItem] = useState([])
    const {id} = useParams()
    const {selectedDrink, setSelectedDrink} = useCartContext();

    
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://pedir-pedir.p89nps.easypanel.host/drinks`);
          setItem(response.data);
        } catch (error) {
          console.error('Error al obtener el producto:', error);
        }
      };
    
      fetchData();
    }, [id]);


  const handleDrinkChange = event => {
    setSelectedDrink(event.target.value);
  };

  return (
    <div>
        <label className='underline' value=""><b>Seleccione una bebida</b></label>
        <br></br>
      <select value={selectedDrink} onChange={handleDrinkChange}>
        <option value="">Seleccione una bebida</option>
        
        {drinks.map(drink => (
          <option key={drink.id} value={drink.name}>
            {drink.name} - {drink.tamaño}
          </option>
        ))}
      </select>
      <br></br>
      <br></br>
      {selectedDrink && <p>Seleccionaste: <b>{selectedDrink}</b></p>}
    </div>
  );
};

export default DrinksDropdown;
